import "./TopNavBar.css"
import testalert from "./testalert.js"
import About from './About.js';
import Projects from './Projects.js';
import Contact from './Contact.js';
import {React, useState} from 'react'

function TopNavBar(props) {
    const [Ahide, setAhide] = useState("block");
    const [Phide, setPhide] = useState("none");
    const [PtitleHide, setPtitleHide] = useState("none");
    const [Chide, setChide] = useState("none");
    const [CtitleHide, setCtitleHide] = useState("none");

  function toggleOff() {
      setAhide("none");
      setPhide("none");
      setPtitleHide("none");
      setChide("none");
    }
    function proj() {
      setAhide("none");
      setPhide("flex");
      setPtitleHide("block");
      setChide("none");
    }

    function about() {
      setAhide("block");
      setPhide("none");
      setPtitleHide("none");
      setChide("none");
    }

    function contact() {
      setAhide("none");
      setPhide("none");
      setPtitleHide("none");
      setChide("block");
    }

return (
        <>
        <div className={"TopNavBar"}>
          <ul>
            <li><button onClick={()=>{about();}}>About</button></li>
            <li><button onClick={()=>{proj();}}>Projects</button></li>
            <li><button onClick={()=>{contact();}}>Contact Me</button></li>
          </ul>
        </div>
          <About val={Ahide}></About>
          <Projects val={Phide} titleVal={PtitleHide}></Projects>
          <Contact val={Chide}></Contact>
        </>
        );
}

export default TopNavBar