import {React, useState} from 'react';
import './App.css';
import TopNavBar from './TopNavBar.js';
import About from './About.js';
import Projects from './Projects.js';
import Contact from './Contact.js';

function App() {

	document.title = "Feran Morgan - SWE/DevOps"

	return (
		<div className="App">
			<TopNavBar></TopNavBar>
		</div>
	);
}

export default App;
