import "./About.css";

function About(props) {
  return (
    <>
      <div style = {{display : props.val}}>
      <h1>Feran Morgan</h1>
      <h2 style={{textAlign: 'center'}}>Automation, efficiency, and optimization!</h2>
      <h1>Experience</h1>
      <p>I started my professional life doing analysis at Lyvebee where I learned to build data pipelines and created aggregation algorithms. It was a lot of fun and taught me how to work with a team.
      <br></br>After that, I found a position at Tesla working with the Autopilot program that enabled me to optimize several workflows. After my improvements, I taught others how to use my methods, often in large groups. I had conquered the challenges of my position at Tesla, and knew it was time to grow. 
      <br></br>I received an amazing offer from Datastax where I would provide technical expertise to their clients relating to cloud computing, Linux administration, DevOps practices, and distributed database technology. I also had the privilege of overseeing a project to test cluster overload, timeouts, automation, data modeling, and front to backend code architecture.</p>
      <h1>Computer Science and Software Engineering</h1>
      <p>I started programming when I was a child. The goal was to modify some of my favorite video games. I knew almost nothing about programming or math but I read scripting tutorials online that helped me. Later in college I took computer science courses and 
         learned object oriented programming, data structures, and algorithms as well as courses for specific languages like C++, Java, and Python. Then I learned SQL, and after that I turned my attention towards HTML, CSS, and Javascript. as well as the technologies that used them like React. 
         These came pretty naturally to me, and the personal projects I've created give me a sense of achievement and ownership that I might not have in another career path.</p>
      <h1>Data Science</h1>
      <p>During my classes in Berkeley I learned about data science through a friend who was majoring in it. I decided to try some projects and learned PANDAS, NUMPY, Matplotlib, Scikit-learn, and little Seaborn. These were a lot of fun as they made it very easy to explore data and run simple machine learning 
         algorithms that I had previous knowledge of through math and statistics.</p>
      <h1>Quantitative Analysis and The Stock Market</h1>
      <p>My last summer in college I decided to try my hand at the stock market. I had basically no money and spent months reading about different trading strategies and companies to invest in. When I finally took the plunge and entered the market, COVID hit and it hit hard. I quickly created a plan that would 
         allow me to turn losses into profits using the heightened volatility. My initial goal was to make 10% profit for the year, but once COVID hit I lost 20%. I didn't know if I'd be able to recover but I did. I ended the year by turning that 20% loss into a 27% total gain. I was careful and cautious but also proactive and tenacious. 
         Since then, I've been expanding my tool kit and learning more about the market and ways to play.</p>
      <h1>Education</h1>
      <p>I graduated from UC Berkeley in Fall 2019 with a bachelor's degree in applied mathematics. 
      My specialized area of study was the theoretical limits of computation, special properties of the integers, and combinatorics. 
      I also took several courses in computer science, specifically relating to object oriented programming, data structures, and courses explicitly pertaining to Python, C++, and Java.</p>
      </div>
      </>
    );
}

export default About