import './Contact.css';

function Contact(props) {

return (
        <div className={"Contact"} style = {{display : props.val}}>
          <h1>Contact Me</h1>
          <h3>LinkedIn: <a href={"https://www.linkedin.com/in/feranmorgan"} class="button">Feran Morgan</a></h3>
          <h3>Github: <a href={"https://github.com/FeranMorgan"} class="button">Main Profile</a></h3>
        </div>
  );
}

export default Contact