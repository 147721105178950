import Template from './Template.js';
import SalaryGraph from "./SalaryGraph.png";
import LinearRegressionT1T2 from "./LinearRegressionT1T2.png";
import UCBerkeleyMajorsSalary from "./UCBerkeleyMajorsSalary.png";
import PersonalWebsite from "./PersonalWebsite.png";
import YAMLAnalyzer from "./YAMLAnalyzer.PNG";
import MasterClone from "./MasterClone.png";
import lolgif from "./lolgif.gif";
import "./Projects.css";
import React from 'react';

function Projects(props) {
  const testval = props.val
  const titleVal = props.titleVal

  const C1link = "https://github.com/FeranMorgan/DataProfessionalSalaryAnalysis";
  const C1text = "An EDA project focused on comparing and contrasting the salaries of data professionals from around the world with particular emphasis on the US. The largest factors correlating with salary were the country in which the survey participant worked and their job title.";
  const C1tools = "Python, PANDAS, Matplotlib, Jupyter.";
  const C2link = "https://github.com/FeranMorgan/LinearRegressionPortugalMath";
  const C2text = "A project that trains models to predict math student’s final test performance using simple and multiple linear regression using factors like daily and weekly alcohol consumption and internet access.";
  const C2tools = "PANDAS, Matplotlib, Seaborn, Sci-kit Learn, Gaussian Naive Bayes, Linear Regression.";
  const C3link = "https://github.com/FeranMorgan/TableauBerkeleyMajors";
  const C3text = "A project focused on detailing the differences in starting salaries for UC Berkeley STEM majors with emphasis on comparing applied/concentrated majors with more generalized/research oriented majors. ";
  const C3tools = "Tableau.";
  const C4link = "";
  const C4text = "A challenge to test my front end development skills. This is one of my favorite projects so far, and I update it frequently as I learn more about web development.";
  const C4tools = "AWS, React, HTML, CSS, Javascript.";
  const C5link = "";
  const C5text = "A powerful tool I developed that allows the user to search a number of files (often YAMLs) for configuration issues and discrepancies.";
  const C5tools = "Bash, shell scripting, vi/vim.";
  const C6link = "https://github.com/FeranMorgan/MastermindClone";
  const C6text = "A console based clone of the code guessing game 'Mastermind'. ";
  const C6tools = "Python.";

  return (
    <>

      <h1 style = {{display : titleVal}}>Projects</h1>
      <p></p>
      <div className="projectRow" style = {{display : testval}}>
        <Template title="Personal Website on AWS" text={C4text} picture={PersonalWebsite} link={C4link} toolsTech={C4tools}/>
        <Template title="YAML Analyzer" text={C5text} picture={YAMLAnalyzer} link={C5link} toolsTech={C5tools}/>
        <Template title="Mastermind Clone" text={C6text} picture={MasterClone} link={C6link} toolsTech={C6tools}/>
      </div>
      <div className="projectRow" style = {{display : testval}}>
        <Template title="Data Professional Salary Analysis" text={C1text} picture={SalaryGraph} link={C1link} toolsTech={C1tools}/>
        <Template title="Linear Regression Predictive Analysis" text={C2text} picture={LinearRegressionT1T2} link={C2link} toolsTech={C2tools}/>
        <Template title="Tableau Visualization for UCB Majors" text={C3text} picture={UCBerkeleyMajorsSalary} link={C3link} toolsTech={C3tools}/>
      </div>
    </>
    );
}

export default Projects