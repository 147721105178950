import "./Template.css"

function Template(props) {

return (
        <div className={"projecttemplate"}>
          <a href={props.link} class="button">
          <h2>{props.title}</h2>
          <img src={props.picture} alt=""/>
          <p>{props.text}</p>
          <p>Tools: {props.toolsTech}</p>
          </a>
          </div>
        );
}

export default Template